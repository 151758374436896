<template>
  <div class="my-6 min-w-full">
    <h1 class="text-3xl">Checkout</h1>
  </div>

  <!-- Summary -->

  <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
    Summary
  </h3>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quantity
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in cart.items" :key="item.product.id" class="bg-white">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                {{ item.product.name }}
              </td>
              <td v-if="item.product.discountPrice" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span class="line-through">{{ item.product.price }}</span> {{ item.product.discountPrice}} €
              </td>
              <td v-else class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ item.product.price }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ item.quantity }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ getItemTotal(item).toFixed(2) }} €
              </td>
            </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colspan="2" class="px-6 py-4 whitespace-nowrap">
                  Total
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{cartTotalLength}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{cartTotalPrice.toFixed(2)}} €
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <hr class="mt-8">

  <!-- Info Checkout -->

  <div class="pt-8">
    <div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Personal Information
      </h3>
      <p class="text-gray-500 text-sm">
        All fields are required
      </p>
    </div>
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-3">
        <label for="first-name" class="block text-sm font-medium text-gray-700">
          First name
        </label>
        <div class="mt-1">
          <input v-model="first_name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="last-name" class="block text-sm font-medium text-gray-700">
          Last name
        </label>
        <div class="mt-1">
          <input v-model="last_name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="email" class="block text-sm font-medium text-gray-700">
          Email address
        </label>
        <div class="mt-1">
          <input v-model="email" id="email" name="email" type="email" autocomplete="email" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="phone" class="block text-sm font-medium text-gray-700">
          Phone
        </label>
        <div class="mt-1">
          <input v-model="phone" id="phone" name="phone" type="text" autocomplete="phone" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="country" class="block text-sm font-medium text-gray-700">
          Country
        </label>
        <div class="mt-1">
          <select v-model="country" id="country" name="country" autocomplete="country" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
            <option>Belgium</option>
            <option>Holland</option>
            <option>France</option>
          </select>
        </div>
      </div>

      <div class="sm:col-span-6">
        <label for="street-address" class="block text-sm font-medium text-gray-700">
          Street address
        </label>
        <div class="mt-1">
          <input v-model="address" type="text" name="street-address" id="street-address" autocomplete="street-address" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="city" class="block text-sm font-medium text-gray-700">
          City
        </label>
        <div class="mt-1">
          <input v-model="city" type="text" name="city" id="city" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>

      <div class="sm:col-span-3">
        <label for="zip" class="block text-sm font-medium text-gray-700">
          ZIP / Postal
        </label>
        <div class="mt-1">
          <input v-model="zipcode" type="text" name="zip" id="zip" autocomplete="postal-code" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
        </div>
      </div>
    </div>

    <div class="text-red-800 mt-4" v-if="errors.length">
      <p v-for="error in errors" :key="error">{{ error }}</p>
    </div>

    <hr class="my-6">

    <div id="card-element" class="mb-5"></div>

    <template v-if="cartTotalLength">
      <hr class="my-6">
      <button class="bg-gray-800 h-12 rounded-xl text-gray-50 hover:bg-coral hover:text-gray-50 px-4" @click="submitForm">Proceed to payment</button>
    </template>

  </div>
</template>

<script>
import axios from "axios";
import {createToast} from "mosha-vue-toastify";

export default {
  name: "Checkout",
  data() {
    return {
      cart: {
        items: []
      },
      stripe: {},
      card: {},
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      address: '',
      zipcode: '',
      city: '',
      country: '',
      errors: []
    }
  },
  mounted () {
    document.title = 'Checkout | Durban'

    this.cart = this.$store.state.cart

    if (this.cartTotalLength > 0) {
      this.stripe = Stripe('pk_test_51JKqHmDYUL0zQxVl6ZcdNpiZci4AHlr5xeqBR9sNowSmqDGu8ZDwo8fMNmYj3nat6ISOyx3wLhUoW9fltaMX50ri00avM4zqbu')
      const elements = this.stripe.elements();
      this.card = elements.create('card', { hidePostalCode: true})
      this.card.mount('#card-element')
    }
  },
  methods: {
    getItemTotal(item) {
      if (item.product.discountPrice) {
        return item.quantity * item.product.discountPrice
      } else {
        return item.quantity * item.product.price
      }
    },
    submitForm() {
      this.$store.commit('setIsLoading', true)
      this.errors = []
      if (this.last_name === '') {
        this.errors.push('The last name field is missing!')
      }
      if (this.first_name === '') {
        this.errors.push('The first name field is missing!')
      }
      if (this.email === '') {
        this.errors.push('The email field is missing!')
      }
      if (this.phone === '') {
        this.errors.push('The phone field is missing!')
      }
      if (this.country === '') {
        this.errors.push('The country field is missing!')
      }
      if (this.address === '') {
        this.errors.push('The address field is missing!')
      }
      if (this.city === '') {
        this.errors.push('The city field is missing!')
      }
      if (this.zipcode === '') {
        this.errors.push('The zip code field is missing!')
      }
      if (!this.errors.length) {
        this.$store.commit('setIsLoading', true)
        this.stripe.createToken(this.card).then(result => {
          if (result.error) {
            this.$store.commit('setIsLoading', false)
            this.errors.push('Something went wrong with Stripe. Please try again')
            console.log(result.error.message)
          } else {
            this.stripeTokenHandler(result.token)
          }
        })
      }
      this.$store.commit('setIsLoading', false)
    },
    async stripeTokenHandler(token) {
      this.$store.commit('setIsLoading', true)
      const items = []
      for (let i = 0; i < this.cart.items.length; i++) {
        const item = this.cart.items[i]
        const obj = {
          product: item.product.id,
          size: item.size,
          specs: item.specs,
          quantity: item.quantity,
          price: item.product.price * item.quantity
        }
        if (item.product.discountPrice) {
          obj.discountPrice = item.product.discountPrice
        }
        items.push(obj)
      }
      const data = {
        'first_name': this.first_name,
        'last_name': this.last_name,
        'email': this.email,
        'address': this.address,
        'zipcode': this.zipcode,
        'country': this.country,
        'city': this.city,
        'phone': this.phone,
        'items': items,
        'stripe_token': token.id,
        'discountCode': this.cart.discountCode
      }
      await axios
          .post('/api/v1/checkout/', data)
          .then(response => {
            this.$store.commit('clearCart')
            this.$router.push('/cart/success')
          })
          .catch(error => {
            this.errors.push('Something went wrong. Please try again')
            console.log(error.response)
            error.response.data.forEach(item => {
              console.log(item)
              createToast({
                    title: item.product.name,
                    description: item.ofs
                  },
                  {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'bounce'
                  })
            })
          })
      this.$store.commit('setIsLoading', false)
    }
  },
  computed: {
    cartTotalPrice() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += curVal.product.price * curVal.quantity
      }, 0)
    },
    cartTotalLength() {
      return this.cart.items.reduce((acc, curVal) => {
        return acc += curVal.quantity
      }, 0)
    }
  }
}
</script>

<style scoped>

</style>
